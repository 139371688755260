import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@mui/material';
import Api from "../../services/api";
import Base from '../../components/layout-base/base';
import { Grid } from '@mui/material';
import { Overlay } from '../../styles/global';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import CustomizedSnackbars from '../../components/material-snackbars';
import { CircularProgress } from '@mui/material';
import CalledRegistrationContent from './index.content';
import NewCalledRegistrationContent from './new-index.content';
import CalledStatusContent from '../calledstatus-list/index.content';
import CalledAssignedUserContent from '../calledassigneduser-list/index.content';
import CalledMexx2Move from '../called-mexx-2move/index';
import CalledOrganizationChecklistContent from '../calledorganizationchecklist-list/index.called.edit';
import TabCalledReportInformation from '../tab-called-report/index.js';
import CalledOrganizationMaterialContent from '../calledorganizationmaterial-list/index';
import CalledUserContent from '../calleduser-list/index.content';
import RelationshipCalleds from '../relationshipcalled-list/index';
import ChildCalleds from '../childcalleds-list/index';
import BallotIcon from '@mui/icons-material/Ballot';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TabHistoryLocation from '../tab-location/index'
import GroupIcon from '@mui/icons-material/Group';
import { addCurrentCalled, addCalled as addCall, addAccompanimentsList, addAccompanimentsListInCalled } from '../../redux/actions/calleds';
import History from '@mui/icons-material/History';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import Repeat from '@mui/icons-material/Repeat';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import QueuePlayNext from '@mui/icons-material/QueuePlayNext';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommentIcon from '@mui/icons-material/Comment';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tab.css'
import { addCalled } from '../../redux/actions/relationshipcalled';
import { browserHistory } from '../../helpers/history';
import CalledTimeEntries from '../called-time-entries';
import AdditionalCalledCost from '../additional-called-cost';
import { AbilityContext } from '../../config/ability-context';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { PapiroConsole } from '../../utils/papiroConsole';
import { evaluateSession } from '../../utils/sessionUtils'
import CalledRequestingUser from '../calledrequestinguser-list/index.content'
import Calledteams from '../calledteams-list/index.content';
import CalledActions from '../called-actions';
import CalledOrganization from '../called-organization';
import { AccountBalance, ReportProblem } from '@mui/icons-material';
import IntegrationKeyApplicationsListContent from '../integration-key-applications-list/index.content';

class CalledRegistration extends Component {

  constructor(props) {
    super(props);
    let calledListPage = localStorage.getItem('calledListPage')

    let propTabIndex = 0
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.propTab && this.props.location.state.propTab > 0) {
      propTabIndex = this.props.location.state.propTab
    }

    const { userSession } = this.props;

    console.log(userSession.user.enablenewscreencalled, 'userSessionuserSession');

    this.state = {
      tabIndex: propTabIndex,
      calledListPage: calledListPage,
      calledPatrimony: false,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      enableNewScreenCalled: userSession.user.enablenewscreencalled == true ? true : false,
    };

    this.isClient = true;

    evaluateSession(userSession)

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
    });

    this.cloneCalled = this.cloneCalled.bind(this);
    this.pauseCalled = this.pauseCalled.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    // this.clickBack = this.clickBack(this);

  }

  pauseCalled() {
    this.setState({ loading: true });
    Api.put(`/calleds/pause/${this.props.calleds.called.id}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "pause.success" })
          });



        }

      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  cloneCalled() {
    this.setState({ loading: true });
    Api.post(`/calleds/clone/${this.props.calleds.called.id}`)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.success" })
          });

          const item = result.data.data;


          let newItem = {
            ...item,
            typeName: item.type.name,
            originRequestName: item.originrequest.name,
            statusName: item.status.name,
            OrganizationCategory: item.organizationcategory.parentname,
            Organization: item.organization.name,
            slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
            slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
            assignedUser: item.assigneduser ? item.assigneduser.name : "",
            requestingUser: item.requestinguser ? item.requestinguser.name : "",
            clone: true
          }
          PapiroConsole.log("=== newItem ===")
          PapiroConsole.log(newItem)
          this.props.addCurrentCalled(newItem);
          this.props.addAccompanimentsList(newItem.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)));

          this.props.addCall(newItem)
          this.props.addAccompanimentsListInCalled(newItem.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)), newItem.id)
          window.location.reload();
        }

      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
      });
  }

  state = {
    matches: window.matchMedia("(max-width: 599px)").matches
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  clickBack = async e => {
    var link = `/${this.props.match.params.lang}/calleds`;
    window.location = link;
  }


  async componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    window.matchMedia("(max-width: 599px)").addListener(handler);
	var id = this.props.match.params.id;
	if(id!= null){
		await this.props.addCalled(id);
	}
	else{
       await this.props.addCalled(this.props.calleds.called.id);
	}

  };

  check2Move = (calledhistorychecks) => {
    if(calledhistorychecks.length > 0) {
      if(calledhistorychecks.length == 1) {
        if(calledhistorychecks[0].historychecktypeid == 5 && calledhistorychecks[0].latitude == "" && calledhistorychecks[0].longitude == "")
          return false
      }
      return true
    }
    return false
  }

  render() {
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }
    PapiroConsole.log(this.props.history)
    
    return (

      <Base >
        <Grid container spacing={6}  >
          <Grid item xs={6} >
            <Button variant='outlined' color='primary' onClick={() => 
              {
                localStorage.setItem('calledListPersistFilter', 'true')
                this.props.history.push(`/${this.props.match.params.lang}/${this.state.calledListPage != null && this.state.calledListPage.length > 0 ? this.state.calledListPage : 'calleds'}`)
              }}>{<FormattedMessage id="back" />}</Button>
          </Grid>



        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={8} />
        </Grid>



        <Grid container>
          <Grid item xs={11} sm={12} >
            <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
              <TabList style={{ borderColor: '#BB8FCE' }}>
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <QueuePlayNext />} {<FormattedMessage id="called.Accom" />}</Tab>
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <CommentIcon />} {<FormattedMessage id="actions" />}</Tab>)}
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <History />} {<FormattedMessage id="calledStatus.header" />} </Tab>
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <GroupIcon />} {<FormattedMessage id="assigned-called-user" />}</Tab>
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <GroupIcon />} {<FormattedMessage id="calledrequestinguser.history.header" />}</Tab>
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <GroupIcon />} {<FormattedMessage id="calledteams.history.header" />}</Tab>
                <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <AccountBalance />} {<FormattedMessage id="called.organization.history.header" />}</Tab>
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <PersonAddIcon />} {intl.formatMessage({id:"professionals.involved"})}</Tab>)}
                {!this.isClient && <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && < QueryBuilderIcon />} {<FormattedMessage id="appointment.hours" />}</Tab>}
                {!this.isClient && <Tab style={{ fontSize: "14px", zIndex: "999999 !important" }}>{!this.state.matches && <AccountBalanceWalletIcon />} {<FormattedMessage id="additional.costs" />}</Tab>}
                <Tab disabled={!this.context.can('View', 'AppraisalCalled')} style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <SummarizeIcon />} {<FormattedMessage id="call report" />}</Tab>
                {!this.state.isClient && !this.state.matches && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} > <Repeat /> {intl.formatMessage({id:"replaced.materials"})} </Tab>)}
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <AllInclusiveIcon />} {<FormattedMessage id="called.rel" />} </Tab>)}
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <BallotIcon />} {<FormattedMessage id="children.called" />} </Tab>)}
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <CheckBoxIcon />} {<FormattedMessage id="menu.organization.checklists" />} </Tab>)}
                {this.props.calleds.called && this.props.calleds.called.calledhistorychecks && this.check2Move(this.props.calleds.called.calledhistorychecks) && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }}>{!this.state.matches && <LocationOnIcon />} {<FormattedMessage id="location.history" />}</Tab>)}
                {!this.isClient && ((this.props.calleds && this.props.calleds.called && this.props.calleds.called.originrequest && (this.props.calleds.called.originrequest.id == 6 || this.props.calleds.called.originrequest.id == 11)) || (this.props.calleds && this.props.calleds.called && this.props.calleds.called.calledhistorychecks && (this.props.calleds.called.calledhistorychecks.filter( item => item.historychecktypeid == 3 || item.historychecktypeid == 4 || item.historychecktypeid == 7 || item.historychecktypeid == 8 || item.historychecktypeid == 9 || item.historychecktypeid == 10).length > 0))) && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <PhoneAndroidIcon />} Mexx 2Move </Tab>)}
                {!this.isClient && (<Tab style={{ fontSize: "14px", zIndex: "999999 !important" }} >{!this.state.matches && <ReportProblem />} Logs</Tab>)}

              </TabList>


              <TabPanel>
                {console.log(this.state.enableNewScreenCalled, 'enableNewScreenCalled')}
                {this.state.enableNewScreenCalled ? 
                  <NewCalledRegistrationContent reload={this.componentDidMount()} /> : 
                  <CalledRegistrationContent reload={this.componentDidMount()} />
                }
              </TabPanel>
              {!this.isClient && (<TabPanel>
                <CalledActions />
              </TabPanel>)}
              <TabPanel>
                <CalledStatusContent />
              </TabPanel>

              <TabPanel>
                <CalledAssignedUserContent />
              </TabPanel>
              <TabPanel>
                <CalledRequestingUser />
              </TabPanel>
              <TabPanel>
                <Calledteams />
              </TabPanel>
              <TabPanel>
                <CalledOrganization />
              </TabPanel>
              {!this.isClient && (
                <TabPanel>
                  <CalledUserContent />
                </TabPanel>
              )}
              {!this.isClient && (
                <TabPanel>
                  <CalledTimeEntries history={this.props.history} match={this.props.match} />

                </TabPanel>
              )}

              {!this.isClient && (
                <TabPanel>
                  <AdditionalCalledCost history={this.props.history} match={this.props.match} />
                </TabPanel>
              )}

              <TabPanel>
                <TabCalledReportInformation isClient={this.isClient} />
              </TabPanel>

              {!this.state.isClient && !this.state.matches && (
                <TabPanel>
                  <CalledOrganizationMaterialContent />
                </TabPanel>
              )}
              {!this.isClient && (
                <TabPanel>
                  <RelationshipCalleds history={this.props.history} match={this.props.match} />
                </TabPanel>
              )}
              {!this.isClient && (
                <TabPanel>
                  <ChildCalleds />
                </TabPanel>
              )}

              {!this.isClient && (
                <TabPanel>
                  <CalledOrganizationChecklistContent
                    calledEdit={true} />
                </TabPanel>
              )}

              {this.props.calleds && this.props.calleds.called && this.props.calleds.called.calledhistorychecks && this.check2Move(this.props.calleds.called.calledhistorychecks) && (
                <TabPanel>
                  <TabHistoryLocation />
                </TabPanel>
              )}

               {!this.isClient && ((this.props.calleds && this.props.calleds.called && this.props.calleds.called.originrequest && (this.props.calleds.called.originrequest.id == 6 || this.props.calleds.called.originrequest.id == 11)) || (this.props.calleds && this.props.calleds.called && this.props.calleds.called.calledhistorychecks && (this.props.calleds.called.calledhistorychecks.filter( item => item.historychecktypeid == 3 || item.historychecktypeid == 4 || item.historychecktypeid == 7 || item.historychecktypeid == 8 || item.historychecktypeid == 9 || item.historychecktypeid == 10).length > 0)))  && (
                <TabPanel>
                  <CalledMexx2Move intl={intl} calledid={this.props.calleds.called.id} />
                </TabPanel>
              )}
              {!this.isClient && (
                <TabPanel>
                  <IntegrationKeyApplicationsListContent />
                </TabPanel>
              )}
            </Tabs>
          </Grid>
        </Grid>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />


      </Base>
    );
  }
}
CalledRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
  relationshipcalled: state.relationshipcalled.relationshipcalled,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addCalled, addCall,  addCurrentCalled, addAccompanimentsList, addAccompanimentsListInCalled }, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledRegistration)));
CalledRegistration.contextType = AbilityContext;
