import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, CircularProgress, Container } from "@mui/material";
import ThumbUp from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import Rating from '@mui/material/Rating';
import CustomizedSnackbars from "../../components/material-snackbars";
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ReactHtmlParser from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderToString, renderToStaticMarkup } from "react-dom/server"
import { injectIntl } from "react-intl";
import { PapiroConsole } from "../../utils/papiroConsole";
import MEFeedbackHeader from "../../components/me-feedback-images/header";
import MEPhoneIcon from "../../components/me-feedback-images/phone-icon";
import MEUserIcon from "../../components/me-feedback-images/user-icon";
import MEDescriptionIcon from "../../components/me-feedback-images/description-icon";
import MEInternetIcon from "../../components/me-feedback-images/internet-icon";
import MELogo from "../../components/me-feedback-images/me-logo";
import MELikeIcon from "../../components/me-feedback-images/like-icon";
import MEUnlikeIcon from "../../components/me-feedback-images/unlike-icon";
import * as SatisfactionSurveyService from '../../services/satisfactionsurvey.service';
import ThumbsUpOrDown from "./components/thumbs-up-or-down";
import RatingScale from "./components/rating-scale";
import RatingScaleOldLayout from "./components/rating-scale-old-layout";
import ThumbsUpOrDownOldLayout from "./components/thumbs-up-or-down-old-layout";
import StarScale from "./components/star-scale";
import clsx from "clsx";
import './styles.css'
import DialogClientFeedbackSuccess from "../../components/dialog-client-feedback-success";
import { ThumbUpSharp } from "@mui/icons-material";
import TODOFeedbackHeader from "../../components/2do-feedback-images/header";
import { OriginOfSurveyResponseEnum } from "src/utils/enums/origin-of-survey-response.enum";

class ChatbotAvaliation extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null
    this.state = {
      isMobile: false,
      feedback: {
        description: "",
        accessToken: "",
        feedbackBo: "",
        DisabledCallRejectionByRequester: false,
        title: null,
        technicalName: null,
        userName: null,
        calledId: null,
        calledDescription: "",
        acesstokenassigned: "",
        HirerId: 0,
        SatisfactionSurveyId: 0,
        chatid: null
      },
      dialogSuccessFeedback: false,
      firstQuestionValue: null,
      colorsZeroToTen: [
        '#EC475C',
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToTen: [
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
        '#0eae9c'

      ],
      colorsOneToFive: [
        '#EC475C',
        '#FB8A31',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #0eae9c 100%)',
        '#0eae9c',
      ],
      feedbackProvided: false,
      showContent: false,
      feedbackError: false,
      ExpiredLink: false,
      newLayout: false,
      calledId: null,
      buttonRender: false,
      loading: false,
      disabledButtons: true,
      openNotification: false,
      notificationVariant: "error",
      disabledResendButton: false,
      notificationMessage: "",
      starRating: null,
      ratingVisible: true,
      showPresentedSolutionQuestion: true,
      flagNotRejectedCalled: false,
      satisfactionSurveyId: 0,
      satisfactionSurveyQuestions: [],
      showCalledRejection: true,
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.selectedFeedback = this.selectedFeedback.bind(this);
    this.insertClassification = this.insertClassification.bind(this);
    this.changeValuesSurveyQuestions = this.changeValuesSurveyQuestions.bind(this);
    this.updateIsMobile  = this.updateIsMobile.bind(this);
    this.handleCloseDialogSuccessFeedback = this.handleCloseDialogSuccessFeedback.bind(this);
    this.handleOpenDialogSuccessFeedback = this.handleOpenDialogSuccessFeedback.bind(this);
  }

  selectedFeedback(event) {
    document.getElementById('ThumbUp').style.color = 'black';
    document.getElementById('ThumbDown').style.color = 'black';
    document.getElementById('ThumbUp').classList.remove("active");
    document.getElementById('ThumbDown').classList.remove("active");

    if (event.currentTarget.id == 'ThumbDown') {
      event.currentTarget.style.color = 'red';
      document.getElementById('ThumbDown').classList.add("active");
      this.setState({ ratingVisible: false });
    } else {
      event.currentTarget.style.color = 'green';
      document.getElementById('ThumbUp').classList.add("active");
      this.setState({ ratingVisible: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    if (this.props.match.params.feedbackAccessToken) {
      await this.getFeedback(this.props.match.params.feedbackAccessToken);
    }
    this.setState({ newLayout: true });
    await this.getSatisfactionSurveyByIdAndHirerId();
    this.updateIsMobile();
    window.addEventListener("resize", this.updateIsMobile);
    this.setState({ showContent: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsMobile);
  }

  handleCloseDialogSuccessFeedback() {
    this.setState({ dialogSuccessFeedback: false });
  }

  handleOpenDialogSuccessFeedback() {
    this.setState({ dialogSuccessFeedback: true });
  }

  updateIsMobile = () => {
    this.setState({ isMobile: window.innerWidth < 959.9 });
  };

  async getFeedback(token) {
    this.setState({ loading: true });
    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };
    if (this.props.location.search && this.props.location.search.length > 20) {
      config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
    }
    await Api.get(`/feedback/get-feedback/${token}`, config)
      .then(result => {
        if (result.data.success) {
          const value = result.data?.data?.accesstoken;

          this.setState(prevState => ({
            loading: false,
            disabledButtons: false,
            feedback: {
              ...prevState.feedback,
              title: result.data.data?.calledTitle ? result.data.data?.calledTitle : '',
              technicalName: result.data.data?.technicalname ? result.data.data?.technicalname : '',
              feedbackBo: result.data.data?.feedbackbo,
              SatisfactionSurveyId: result.data.data?.satisfactionsurveyid,
              'accesstoken': value,
              HirerId: result.data.data?.hirerid,
              chatid: result.data.data?.chatid
            },
            flagNotRejectedCalled: false
          }));
        }
        else {
          this.setState({
            disabledButtons: true,
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, disabledButtons: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }),
          feedbackError: true
        });
        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {
          if (err.response.data.errors[0] == intl.formatMessage({ id: "nps.email.rating.link.expired.feedback" })) {
            this.addButtonExpiredToken()
          }
          else {
            this.setState({ feedbackError: true });
            if (err.response.data.errors[0] == intl.formatMessage({ id: "feedback.provided" })) {

              this.setState({ feedbackProvided: true });
            }
          }
          this.setState({ feedbackError: true });
        }
      });
    this.setState({ loading: false });
  };

  async getSatisfactionSurveyByIdAndHirerId() {
    let hirerId = this.state.feedback.HirerId;
    let SatisfactionSurveyId = this.state.feedback.SatisfactionSurveyId
    if (hirerId && SatisfactionSurveyId) {
      this.setState({ loading: true });
      var result = await SatisfactionSurveyService.getByIdAndHirerId(SatisfactionSurveyId, hirerId);

      if (result.success) {
        let satisfactionsurveyquestions = result.data?.satisfactionsurveyquestions
        let showCalledRejection = true
        if (satisfactionsurveyquestions && satisfactionsurveyquestions.length > 0) {
          satisfactionsurveyquestions = satisfactionsurveyquestions.sort((a, b) => a.id - b.id)
          const satisfactionSurveyQuestionsList = satisfactionsurveyquestions.map((item) => {
            if (item.calledresolvedindicator == true) {
              showCalledRejection = false
            }
            return {
              id: item.id,
              name: item.name,
              indcsatisfiedserviceindex: item.indcsatisfiedserviceindex,
              satisfactionsurveyquestionstypeid: item.satisfactionsurveyquestionstypeid,
              response: item.indcsatisfiedserviceindex && this.state.starRating ? this.state.starRating : null,
              calledresolvedindicator: item.calledresolvedindicator
            }
          });

          this.setState({ loading: false, satisfactionSurveyId: result.data.id, satisfactionSurveyQuestions: satisfactionSurveyQuestionsList, showCalledRejection: showCalledRejection  });
        }
        this.setState({ loading: false });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      feedback: {
        ...prevState.feedback,
        [name]: value
      }
    }));
  }

  addButtonExpiredToken() {
    const intl = this.props.intl;
    const { classes } = this.props;
    if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
      this.ButtonExpired =
        <Button
          onClick={this.resendEmail}
          variant="contained"
          color="primary"
          className={classes.submit}
          style={{ width: "200px", backgroundColor: '#09316F', fontWeight: 'bold', fontSize: '1em', borderRadius: '20px' }} >
          {intl.formatMessage({ id: "resend.feedback.link" })}
        </Button>
    }
    else {
      this.ButtonExpired =
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={this.resendEmail}
          style={{ width: "300px", marginRight: '16px' }}

        >
          {intl.formatMessage({ id: "resend.feedback.link" })}
        </Button>
    }
    this.setState({ ExpiredLink: true, loading: false });
  }

  insertClassification(feedback) {
    const intl = this.props.intl;

    const { starRating } = this.state;
    const { history } = this.props;

    Api.post("/calledclassifications/external", { ObservationAssigned: feedback.description, NoteAssigned: starRating, CalledId: feedback.calledid })
      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "feedback.assigned.feedback.received" })
          });
          setTimeout(function () { history.push("/"); }, 1000);
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "feedback.assigned.error" })
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      })

    this.setState({ loading: false })
  }

  changeValuesSurveyQuestions(index, value, indcsatisfiedserviceindex) {
    const values = [...this.state.satisfactionSurveyQuestions];
    values[index]['response'] = value;
    if (values[index]['calledresolvedindicator'] == true && (!this.state.feedback.DisabledCallRejectionByRequester)) {
      if (value == true) {
        this.setState({ ratingVisible: true })
      } else {
        this.setState({ ratingVisible: false })
      }
    }
    this.setState({
      satisfactionSurveyQuestions: values,
      starRating: indcsatisfiedserviceindex ? value : this.state.starRating
    });
  };

  checkValidations() {
    const intl = this.props.intl;
    let validation = true;

    if (this.state.satisfactionSurveyQuestions && this.state.satisfactionSurveyQuestions.length > 0) {
      this.state.satisfactionSurveyQuestions.forEach(element => {
        if (element.response == null) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "answer.all.questions" }),
          });
          validation = false
        }
      });
    }

    /*
    Validação Anterior

    const { starRating } = this.state;

    if ((starRating == undefined || starRating == null) && this.state.ratingVisible) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "feedback.assigned.grade.attendance" }),
      });
      validation = false
    }
    */

    return validation;
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    const { starRating } = this.state;
    var feedbackBoValue;
    let validations = this.checkValidations()
    if (validations) {
      if (this.state.ratingVisible == true) {
        feedbackBoValue = "1"
      }
      else {
        feedbackBoValue = "2"
      }
      const { description, accesstoken } = this.state.feedback;
      const { history } = this.props;
      if (feedbackBoValue == '2' && description == "") {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({ id: "feedback.assigned.called.reopen.justification" })
        });
      } else {
        let config = {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        };
        if (this.props.location.search && this.props.location.search.length > 20) {
          config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;
        }
        
        var originOfSurveyResponse = OriginOfSurveyResponseEnum.EMAIL;

        if(this.props && this.props.match && this.props.match.params && this.props.match.params.source && this.props.match.params.source == "whatsapp"  ){
          originOfSurveyResponse = OriginOfSurveyResponseEnum.WHATSAPP;
        }

        var input = { 
          Description: description, 
          FeedbackBo: parseInt(feedbackBoValue), 
          AccessToken: accesstoken, 
          Rating: starRating, 
          ApplicationOriginId: 2, 
          SatisfactionSurveyQuestions: this.state.satisfactionSurveyQuestions, 
          SatisfactionSurveyId: this.state.satisfactionSurveyId, 
          OriginOfSurveyResponse : originOfSurveyResponse,
          ChatId: this.state.feedback.chatid
        };
        
        Api.put("/feedback/chatbot-avaliation", input, config)
          .then(result => {
            if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "success",
                notificationMessage: intl.formatMessage({ id: "feedback.assigned.feedback.received" }),
                disabledButtons: true
              });
              this.handleOpenDialogSuccessFeedback()
              setTimeout(function () { history.push("/"); }, 8000);
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {
              if (err.response.data.errors[0] == intl.formatMessage({ id: "nps.email.rating.link.expired.feedback" })) {
                this.addButtonExpiredToken()
              }
            }
          })
      }
    }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const intl = this.props.intl;
    const styles = {
      regularFont: {
        color: "black",
        fontWeight: "700",
        margin: "0px",
        padding: "0px",
      },
      grayFont: {
        margin: "0px",
        padding: "0px",
        display: 'flex',
        color: "#909497 ",
        fontSize: "15px",
        fontWeight: "500",
      },
      alignMiddle: {
        verticalAlign: "middle"
      },
    }
    const { classes } = this.props;
    const { feedback } = this.state;
    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }
    if (Api != null && (Api.getSassHirerId() == 1011 || Api.getSassHirerId() == 15)) {
      const { isMobile } = this.state;
      return (
        <Container component="main" maxWidth="md" className={isMobile ? "feedback-main-container" : ""} >
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
          <Grid container spacing={2} style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: 0 }}>
              <MEFeedbackHeader style={{ width: '100%', maxWidth: '100%' }} />
              <div style={{ backgroundColor: '#09316F', color: '#FFF', padding: '2% 10% 1% 5%', display: 'inline-block', borderTopRightRadius: 150, borderBottomRightRadius: 150, position: 'relative', left: 0, bottom: '15%' }} className={isMobile ? "feedback-bg-help-text" : ""}>
                <h4 style={{ display: 'inline-block', margin: 0 }}>   
                    <strong>Ajude a melhorar nosso atendimento!</strong>
                </h4>
                <br />
                  <h5 style={{ display: 'inline-block', margin: 0 }}>Sua opinião é muito importante!</h5>
              </div>
            </Grid>
            {/* CASO NÃO OCORRA NENHUM ERRO - EXEMPLO : TOKEN EXPIRADO, LINK DE EXPIRAÇÃO ETC */}
            {!this.state.feedbackError && this.state.showContent &&
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }} >
                  <Grid container spacing={1} style={{ paddingLeft: '10%' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }} >
                      <MEPhoneIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;<strong>Conversa:</strong> {this.state.feedback.chatid}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <MEUserIcon style={{ width: 25 }} />&nbsp;&nbsp;&nbsp;&nbsp;<strong>Responsável pelo atendimento:</strong>&nbsp;{this.state.feedback.technicalName}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '4%', padding: '1% 0', backgroundColor: '#09316F', display: 'flex', color: '#FFF', justifyContent: 'center' }} className={isMobile ? "feedback-bg-answers-text" : ""} >
                  <h5><strong>Responda as perguntas abaixo, leva menos de 1 minuto.</strong></h5>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={isMobile ? "feedback-form" : ""} >
                  <Grid container spacing={1} style={{ paddingLeft: '10%' }}>
                    <Grid item xs={12} sm={12} md={6} lg={5} xl={4} >
                      <p style={{ color: '#09316F' }}><strong>01.</strong>Sua solicitação foi resolvida?</p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={7} xl={8} >
                      <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === true ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({ ratingVisible: true })
                          }}>
                          <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
                          <MELikeIcon style={{ width: 30 }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === false ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({ ratingVisible: false })
                          }}>
                          <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
                          <MEUnlikeIcon style={{ width: 30 }} />
                        </div>
                      </div>
                    </Grid>
                    {this.state?.satisfactionSurveyQuestions?.length > 0 &&
                      <>
                        {this.state.satisfactionSurveyQuestions.map((item, index) => (
                          <>
                            {item.satisfactionsurveyquestionstypeid == 1 &&
                              <ThumbsUpOrDown 
                                fqa={this.state.firstQuestionValue} 
                                newlayout={true} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                index={index} 
                                title={item.name} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                            {item.satisfactionsurveyquestionstypeid == 2 &&
                              <RatingScale 
                                starRating={this.state.starRating} 
                                indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                initial={0} 
                                end={10} 
                                colors={this.state.colorsZeroToTen} 
                                title={item.name} 
                                index={index} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                            {item.satisfactionsurveyquestionstypeid == 3 &&
                              <RatingScale 
                                starRating={this.state.starRating} 
                                indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                initial={1} 
                                end={10} 
                                colors={this.state.colorsOneToTen} 
                                title={item.name} 
                                index={index} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                            {item.satisfactionsurveyquestionstypeid == 4 &&
                              <StarScale 
                                starRating={this.state.starRating} 
                                indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                end={9} 
                                title={item.name}
                                index={index} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                            {item.satisfactionsurveyquestionstypeid == 5 &&
                              <RatingScale 
                                starRating={this.state.starRating} 
                                indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                initial={1} 
                                end={5} 
                                title={item.name} 
                                colors={this.state.colorsOneToFive} 
                                index={index} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                            {item.satisfactionsurveyquestionstypeid == 6 &&
                              <StarScale 
                                starRating={this.state.starRating} 
                                indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                end={5} 
                                title={item.name} 
                                index={index} 
                                disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                              />
                            }
                          </>
                        ))}
                      </>
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '3%' }} >
                      <p style={{ color: '#09316F' }}><strong>Comentários:</strong> <span style={{ fontSize: '0.8em' }} >(Opcional)</span></p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <TextField
                        variant="outlined"
                        id="outlined-multiline-static"
                        label={intl.formatMessage({ id: "comment" })}
                        name="description"
                        disabled={this.state.disabledButtons}
                        multiline
                        rows="8"
                        fullWidth
                        inputProps={{ maxLength: 800 }}
                        onChange={this.handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: '8%' }} >
                      <Grid container spacing={1} >
                        <Grid item xs={12} sm={12} md={6} lg={8} xl={8} />
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                          <Button
                            onClick={this.handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            id="submit"
                            disabled={this.state.disabledButtons}
                            style={{ width: "200px", backgroundColor: '#09316F', fontWeight: 'bold', fontSize: '1em', borderRadius: '20px' }} >
                            ENVIAR AVALIAÇÃO
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            }

            {/*CASO PARA LINK EXPIRADO */}
            {this.state.feedbackError && this.state.ExpiredLink &&
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      O prazo para realizar essa pesquisa de satisfação foi expirado
                    </b>
                  </p>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Clique no botão abaixo para reenviar um novo link.
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '160px' }}>
                  {!this.state.disabledResendButton && (
                    <>
                      {this.ButtonExpired}
                    </>
                  )}
                </Grid>
              </Grid>
            }

            {/*CASO PARA PESQUISA RESPONDIDA */}
            {this.state.feedbackError && this.state.feedbackProvided &&
              <Grid item xs={12} >
                <Grid item xs={12}>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Essa pesquisa já foi respondida.
                    </b>
                  </p>
                  <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                    <b style={{ color: "#335386" }}>
                      Sua opinião é muito importante e vai nos ajudar a melhorar o atendimento.
                    </b>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  {!this.state.disabledResendButton && (
                    <>
                      {this.ButtonExpired}
                    </>
                  )}
                </Grid>
              </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignSelf: 'flex-end' }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4%' }} >
                <MEInternetIcon style={{ width: 30 }} />&nbsp;<strong style={{ color: '#2D4B7A' }} > <a href="https://www.gov.br/gestao/pt-br/assuntos/central-de-atendimento" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>www.gov.br/centraldeatendimento</a></strong>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: '#09316F', display: 'flex', justifyContent: 'center', padding: '5% 0', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', marginTop: '2%' }} >
                <MELogo style={{ width: '40%' }} />
              </Grid>
            </Grid>
          </Grid>

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />

          <DialogClientFeedbackSuccess
            open={this.state.dialogSuccessFeedback}
            handleCloseDialogSuccessFeedback={this.handleCloseDialogSuccessFeedback}
          />
        </Container >
      )
    } else {
      return (
        <Container component="main" maxWidth="md">
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingLeft: 0 }}>
            <TODOFeedbackHeader style={{ width: '100%', maxWidth: '100%' }} />
            <div style={{ backgroundColor: '#f4781f', color: '#FFF', padding: '2% 10% 1% 5%', display: 'inline-block', borderTopRightRadius: 150, borderBottomRightRadius: 150, position: 'relative', left: 0, bottom: '15%' }} >
              <h4 style={{ display: 'inline-block', margin: 0 }}>
                {this.state.feedbackError && this.state.ExpiredLink && this.state.showContent &&
                  <strong>Prazo de avaliação expirado!</strong>
                }
                {this.state.feedbackError && this.state.feedbackProvided && this.state.showContent &&
                  <strong>Agradecemos a colaboração!</strong>
                }
                {!this.state.feedbackError && this.state.showContent &&
                  <strong>Ajude a melhorar nosso atendimento!</strong>
                }
              </h4>
              <br />
              {!this.state.feedbackError && this.state.showContent &&
                <h5 style={{ display: 'inline-block', margin: 0 }}>Sua opinião é muito importante!</h5>
              }
            </div>
          </Grid>
          <div>
            {
              !this.state.feedbackError && this.state.showContent && (
                <div>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div style={{ marginTop: "20px" }}>
                    <h4 style={styles.grayFont}> 
                      <strong>Conversa:</strong>&nbsp;{this.state.feedback.chatid}
                    </h4>
                  </div> 
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div style={{ marginTop: "20px" }}>
                    <h4 style={styles.grayFont}> 
                      <strong>Responsável pelo atendimento:</strong>&nbsp;{this.state.feedback.technicalName}
                    </h4>
                  </div>    
                </div>
              )
            } 
          </div>
          {!this.state.feedbackError && this.state.showContent &&
            <>
              {this.state.feedback.feedbackBo > 0 ? (
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                  <div>
                    <h4 style={styles.regularFont}>Essa avaliação já foi respondida.</h4>
                  </div>
                </div>
              ) : (
                <div>
                  {!this.state.feedbackError && !this.state.ExpiredLink && this.state.showContent &&
                    <>
                      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
                        <p style={{ color: '#09316F' }}><strong>01.</strong>Sua solicitação foi resolvida?</p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={7} xl={8} style={{ marginTop: "15px" }} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                          <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === true ? '#CCC' : '#EEE', borderRight: '1px solid #CCC', cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({ ratingVisible: true })
                            }}>
                            <p style={{ color: '#595959', paddingRight: '10%' }}><strong>SIM</strong></p>
                            <MELikeIcon style={{ width: 30 }} />
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', padding: '2% 5%', backgroundColor: this.state.ratingVisible === false ? '#CCC' : '#EEE', borderLeft: '1px solid #CCC', cursor: 'pointer' }}
                            onClick={() => {
                              this.setState({ ratingVisible: false })
                            }}>
                            <p style={{ color: '#595959', paddingRight: '10%' }}><strong>NÃO</strong></p>
                            <MEUnlikeIcon style={{ width: 30 }} />
                          </div>
                        </div>
                      </Grid>
                      {(this.state?.satisfactionSurveyQuestions?.length > 0) &&
                        <>
                          {this.state.satisfactionSurveyQuestions.map((item, index) => (
                            <>
                              {item.satisfactionsurveyquestionstypeid == 1 &&
                                <ThumbsUpOrDown 
                                  fqa={this.state.firstQuestionValue} 
                                  newlayout={false} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  title={item.name} 
                                />
                              }
                              {item.satisfactionsurveyquestionstypeid == 2 &&
                                <RatingScale 
                                  starRating={this.state.starRating} 
                                  indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  initial={0} 
                                  end={10} 
                                  title={item.name} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  colors={this.state.colorsZeroToTen} 
                                />
                              }
                              {item.satisfactionsurveyquestionstypeid == 3 &&
                                <RatingScale 
                                  starRating={this.state.starRating} 
                                  indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  initial={1} 
                                  end={10} 
                                  title={item.name} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  colors={this.state.colorsOneToTen} 
                                />
                              }
                              {item.satisfactionsurveyquestionstypeid == 4 &&
                                <RatingScaleOldLayout 
                                  starRating={this.state.starRating} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  end={10} 
                                  title={item.name} 
                                />
                              }
                              {item.satisfactionsurveyquestionstypeid == 5 &&
                                <RatingScale 
                                  starRating={this.state.starRating} 
                                  indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  initial={1} 
                                  end={5} 
                                  title={item.name} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  colors={this.state.colorsOneToFive} 
                                />
                              }
                              {item.satisfactionsurveyquestionstypeid == 6 &&
                                <RatingScaleOldLayout 
                                  starRating={this.state.starRating} 
                                  index={index} 
                                  disabledCallRejectionFlag={ (this.state.feedback.DisabledCallRejectionByRequester || !(this.state.showCalledRejection) ) } 
                                  indcsatisfiedserviceindex={item.indcsatisfiedserviceindex} 
                                  changeValuesSurveyQuestions={this.changeValuesSurveyQuestions} 
                                  end={5} 
                                  title={item.name} 
                                />
                              }
                            </>
                          ))}
                        </>
                      }
                    </>
                  }
                  <form className={classes.form} onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          id="outlined-multiline-static"
                          label={intl.formatMessage({ id: "comment" })}
                          name="description"
                          disabled={this.state.disabledButtons}
                          multiline
                          rows="8"
                          fullWidth
                          variant="outlined"
                          inputProps={{ maxLength: 800 }}
                          onChange={this.handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <div style={{ textAlign: "right", marginTop: '20px' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="submit"
                        disabled={this.state.disabledButtons}
                        style={{ width: "200px" }}
                      >
                        ENVIAR AVALIAÇÃO
                      </Button>
                    </div>
                  </form>
                </div>
              )}
            </>
          }

          {/* LINK EXPIRADO */}
          {this.state.feedbackError && this.state.ExpiredLink &&
            <Grid item xs={12} style={{ marginTop: '50px' }}>
              <Grid item xs={12}>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#335386" }}>
                    O prazo para realizar essa pesquisa de satisfação foi expirado
                  </b>
                </p>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#335386" }}>
                    Clique no botão abaixo para reenviar um novo link.
                  </b>
                </p>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '160px' }}>
                {!this.state.disabledResendButton && (
                  <>
                    {this.ButtonExpired}
                  </>
                )}
              </Grid>
            </Grid>
          }

          {/* CASO PARA AVALIAÇÃO RESPONDIDA */}
          {this.state.feedbackError && this.state.feedbackProvided &&
            <Grid item xs={12} style={{ marginTop: '50px' }}>
              <Grid item xs={12}>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#f4781f" }}>
                    Essa avaliação já foi respondida.
                  </b>
                </p>
                <p style={{ fontFamily: "arial", fontSize: "1.3rem", textAlign: "center", color: "#1351b4" }}>
                  <b style={{ color: "#f4781f" }}>
                    Sua opinião é muito importante e vai nos ajudar a melhorar o atendimento.
                  </b>
                </p>
              </Grid>
              <Grid item xs={12}>
                {!this.state.disabledResendButton && (
                  <>
                    {this.ButtonExpired}
                  </>
                )}
              </Grid>
            </Grid>
          }


          <DialogClientFeedbackSuccess
            open={this.state.dialogSuccessFeedback}
            handleCloseDialogSuccessFeedback={this.handleCloseDialogSuccessFeedback}
          />

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
      );
    }
  }
}
export default injectIntl(withStyles(Styles)(ChatbotAvaliation));
