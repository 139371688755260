import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, TextareaAutosize, Grid, Typography, CircularProgress, Container, Divider } from '@mui/material';

import { QueuePlayNext, DeleteForever, LensTwoTone } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Service from '../../services/segment.service';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import SimpleAutoComplete from "../../components/auto-complete/autocomplete.js";
import Api2Talk from "../../services/api2talk";
import ReactQuill from 'react-quill' // ES6
import { styled } from "@mui/material/styles";
import { PapiroConsole } from 'src/utils/papiroConsole';
import { Autocomplete } from '@mui/material';

import {
  Box,

} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddIcon from "@mui/icons-material/Add";

import DeleteIcon from "@mui/icons-material/Delete";

import {
  Table, TableBody, TableCell, TableHead, TableRow, TableContainer,
  Paper
} from "@mui/material";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#ffffff", // Cor de fundo branca
    color: "#000000", // Cor do texto preta
    fontSize: "14px", // Tamanho do texto
    padding: "20px", // Espaçamento interno
    border: "1px solid #ddd", // Borda discreta
    borderRadius: "4px", // Bordas arredondadas
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra leve
    whiteSpace: "pre-line", // Permite quebra de linha
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#ffffff", // Cor da seta
  },
}));

class TemplateMessageRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.dynamicText = " Olá {{1}}, seu pedido #{{2}} está pronto para retirada no endereço {{3}}. Horário: {{4}}."


    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name: '',
      label: '',
      languageCode: "pt_BR",
      headerText: "",
      bodyText: "",
      footerText: "",
      messageProviderId: null,
      registrationnumberrequestid: null,
      templatetype: null,
      satisfactionsurveyid: null,



    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      exampleText:
        <>
          Esse é o texto especificado na API que será personalizado para o cliente. <br /><br />Para adição de variáveis é necessário adicionar números consecutivos entre chaves duplas como o exemplo a seguir : <br /><br /> <strong>{this.dynamicText}</strong>
        </>,

      buttons: [],
      showForm: false,
      buttonText: "",
      buttonUrl: "",
      buttonUrlSuffixExample: "",
      buttonTemplateVariable: null,
      buttonType: null,
      error: "",
      registrationNumberRequestList: [],
      typeList: [
        { id: 1, name: "URL estática" },
        { id: 2, name: "Resposta rápida" },
        { id: 3, name: "URL dinâmica" }
      ],
      templateTypeList: [
        { id: 1, name: "Padrão" },
        { id: 2, name: "Opt-in" },
        { id: 3, name: "Pesquisa de satisfação" }
      ],
      satisfactionSurveyList: [],
      text: "",
      variablesBody: [],
      variablesHeader: [],
      templateVariablesOptions: [],
      variableBodySelectMappings: {},
      variableHeaderSelectMappings: {},

      variableBodyExampleMappings: {},
      variableHeaderExampleMappings: {},



    };


    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],

      ],
    }

    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',

    ]
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    // this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {

    this.fetchOptionsMessageProvider();
    this.fetchOptionsRegistrationsNumbersActives();
    this.getSatisfactionSurveyOptions();
    this.getTemplateVariablesOptions();


  };

  fetchOptionsMessageProvider = async () => {
    this.setState({ loading: true });
    Api2Talk.get(`/messageprovider`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {
          const messageProviderOptions = result.data.data;
          this.setState(prevState => ({
            optionsMessageProviders: messageProviderOptions
          }))
        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  }

  async fetchOptionsRegistrationsNumbersActives() {

    this.setState({ loading: true });
    Api2Talk.get(`/registrationphonenumber/all/active`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {
          this.setState({
            registrationNumberRequestList: result.data.data,
          })

        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  };


  async getSatisfactionSurveyOptions() {

    this.setState({ loading: true });
    Api.get(`/satisfactionsurvey/simple/all`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {
          this.setState({
            satisfactionSurveyList: result.data.data,
          })

        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  };

  async getTemplateVariablesOptions() {

    this.setState({ loading: true });
    Api2Talk.get(`/template-variable/simple/all`)
      .then(result => {
        this.setState({ loading: false });
        if (result.data.success) {

          PapiroConsole.log("result getTemplateVariablesOptions")
          PapiroConsole.log(result)
          this.setState({
            templateVariablesOptions: result.data.data,
          })

        } else {
          this.fetchDataNotSuccess(result);
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.fetchDataThrowError(err);
        Api.kickoff(err);
      });
  };



  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
      loading: false, openNotification: true, notificationVariant: "error",
      notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
    });
  }

  handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault(); // Evita a inserção padrão do espaço
      this.setState((prevState) => ({

        item: {
          ...prevState.item,
          ["name"]: prevState.item.name.length < 512 ? prevState.item.name + "_" : prevState.item.name
        },
      }));
    }
  };

  handleInputChange(e) {
    const name = e.target.name;
    var value = e.target.value;

    if (name == "name") {
      value = value.toLowerCase()
        .replace(/[^a-zA-Z0-9_]/g, "") // Permite apenas letras, números e "_"
        .slice(0, 512); // Limita a 512 caracteres
    }

    if (name == "label") {
      value = value
        .slice(0, 255); // Limita a 512 caracteres
    }
    if (name == "headerText" || name == "footerText") {
      value = value
        .slice(0, 60); // Limita a 512 caracteres
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));



  };

  handleChangeAutoComplete = async (stateName, value) => {
    let data = value && value.id > 0 ? value : null;

    if (stateName == "buttonType") {
      this.setState({
        buttonType: data,
        buttonUrl: ""
      })
    }
    else if (stateName == "buttonTemplateVariable") {
      this.setState({
        buttonTemplateVariable: value,
      })
    }
    else if (stateName == "templatetype") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value || "",
          satisfactionsurveyid: null
        }
      }));

    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: data
        }
      }));
    }


  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value || ""
      }
    }));
  }




  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ loading: true });


    const { name, label, languageCode, bodyText, headerText, footerText, messageProviderId, registrationnumberrequestid, templatetype, satisfactionsurveyid } = this.state.item;


    const variablesParametersBody = this.state.variablesBody.map((num) => ({
      order: num, // Nome da variável, ex: "{{1}}"
      templateVariableOptionSelected: this.state.variableBodySelectMappings[num], // Pega primeiro o Autocomplete, se não tiver, pega o TextField
      exampleOptionSelected: this.state.variableBodyExampleMappings[num], // Pega primeiro o Autocomplete, se não tiver, pega o TextField
    }));

    const variablesParametersHeaders = this.state.variablesHeader.map((num) => ({
      order: num, // Nome da variável, ex: "{{1}}"
      templateVariableOptionSelected: this.state.variableHeaderSelectMappings[num], // Pega primeiro o Autocomplete, se não tiver, pega o TextField
      exampleOptionSelected: this.state.variableHeaderExampleMappings[num], // Pega primeiro o Autocomplete, se não tiver, pega o TextField
    }));

    if (variablesParametersBody && variablesParametersBody.length > 0) {
      const unfilledVars = variablesParametersBody.some(item => 
        Object.values(item).some(valor => valor === null || valor === undefined || valor === "")
      );
      if (unfilledVars) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Preencha todas as variáveis antes de enviar."
        });
        return;
      }
    }

    
    if (variablesParametersHeaders && variablesParametersHeaders.length > 0) {
      const unfilledVars = variablesParametersHeaders.some(item => 
        Object.values(item).some(valor => valor === null || valor === undefined || valor === "")
      );
      if (unfilledVars) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Preencha todas as variáveis antes de enviar."
        });
        return;
      }
    }

    PapiroConsole.log("variablesParametersBody aqui submit")
    PapiroConsole.log(variablesParametersBody)
    PapiroConsole.log("variablesParametersHeaders aqui submit")
    PapiroConsole.log(variablesParametersHeaders)


    

    PapiroConsole.log('variablesParametersHeaders aqui')
    PapiroConsole.log(variablesParametersHeaders)
    PapiroConsole.log('this.state.variablesHeader aqui')
    PapiroConsole.log(this.state.variablesHeader)

    // if (this.state.variablesHeader && this.state.variablesHeader.length > 0) {
    //   this.state.variablesHeader.forEach(element => {


        
    //   });
    //   PapiroConsole.log('unfilledVars')
    //   PapiroConsole.log(unfilledVars)
    //   if (unfilledVars.length > 0) {
    //     this.setState({
    //       loading: false,
    //       openNotification: true,
    //       notificationVariant: 'error',
    //       notificationMessage: "Preencha todas as variáveis antes de enviar."
    //     });
    //     return;
    //   }
    // }


    let data = {
      name,
      label,
      languageCode,
      bodyText,
      headerText,
      footerText,
      messageProviderId: messageProviderId.id,
      buttons: this.state.buttons,
      registrationnumberrequestid: registrationnumberrequestid.id,
      templatetype: templatetype.id,
      satisfactionsurveyid: satisfactionsurveyid != null && satisfactionsurveyid.id ? satisfactionsurveyid.id : null,
      variablesParametersBody: variablesParametersBody,
      variablesParametersHeader: variablesParametersHeaders

    }

    Api2Talk.post('/templatemessage', data)
      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            item: this.initialState,
            buttons: [],
            files: [],
            variablesBody: [],
            variablesHeader: [],
            templateVariablesOptions: [],
            variableBodySelectMappings: {},
            variableHeaderSelectMappings: {},

            variableBodyExampleMappings: {},
            variableHeaderExampleMappings: {},
          });
        }
        else {

          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleAddButtonClick = () => {
    this.setState({ showForm: true });
  };

  handleInputChangeTable = (field, value) => {
    this.setState({ [field]: value, error: "" });
  };

  handleAdd = () => {

    const { buttons, buttonText, buttonUrl, buttonType, buttonUrlSuffixExample, buttonTemplateVariable } = this.state;

    PapiroConsole.log("buttons")
    PapiroConsole.log(buttons)
    PapiroConsole.log("buttonType")
    PapiroConsole.log(buttonType)

    if (buttonType == null) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "O campo tipo do botão é obrigatório"

      });
      return;

    }
    else if (buttonType.id == 1) {

      const filtered = buttons.filter(item => item.buttonTypeId === 1);
      PapiroConsole.log("filtered")
      PapiroConsole.log(filtered)
      if (filtered && filtered.length >= 2) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Apenas dois botões de url estática podem ser adicionados."
        });
        return;
      }
      else if (!buttonText || !buttonUrl) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Preencha os campos de url e texto."

        });
        return;
      }

    }
    else if (buttonType.id == 2) {
      if (!buttonText) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Preencha o campo de texto"

        });
        return;
      }
    }

    else if (buttonType.id == 3) {

      const filtered = buttons.filter(item => item.buttonTypeId === 3);
      PapiroConsole.log("filtered")
      PapiroConsole.log(filtered)
      if (filtered && filtered.length >= 2) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Apenas dois botões de url dinâmica podem ser adicionados."
        });
        return;
      }
      else if (!buttonText || !buttonUrl || !buttonUrlSuffixExample || (!buttonTemplateVariable || (buttonTemplateVariable && buttonTemplateVariable.id == 0))) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Preencha os campos de url , texto, url de amostra e associar variavel ."
        });
        return;
      }

    }

    this.setState({
      buttons: [...buttons, { text: buttonText, url: buttonUrl, buttonTypeId: buttonType != null ? buttonType.id : null, buttonTypeName: buttonType != null ? buttonType.name : null, buttonUrlSuffixExample: buttonUrlSuffixExample, buttonTemplateVariableId: buttonTemplateVariable != null ? buttonTemplateVariable.id : null }],
      buttonText: "",
      buttonUrl: "",
      buttonType: null,
      showForm: false,
      buttonUrlSuffixExample: "",
      buttonTemplateVariable: null
    });


    // const { buttons, buttonText, buttonUrl } = this.state;
    // if (buttons.length >= 2) {
    //   this.setState({ error: "Apenas dois botões podem ser adicionados." });
    //   return;
    // }
    // if (!buttonText || !buttonUrl) {
    //   this.setState({ error: "Preencha ambos os campos." });
    //   return;
    // }

    // this.setState({
    //   buttons: [...buttons, { text: buttonText, url: buttonUrl }],
    //   buttonText: "",
    //   buttonUrl: "",
    //   showForm: false,
    // });
  };

  handleRemove = (index) => {
    this.setState((prevState) => ({
      buttons: prevState.buttons.filter((_, i) => i !== index),
    }));
  };

  addVariableBody = () => {

    const lastVarNumber = this.state.variablesBody[this.state.variablesBody.length - 1];


    // PapiroConsole.log("this.state.variableBodyExampleMappings[lastVarNumber]")
    // PapiroConsole.log(this.state.variableBodyExampleMappings[lastVarNumber])

    // Verifica se a última variável foi preenchida antes de adicionar outra
    if (lastVarNumber && !this.state.variableBodySelectMappings[lastVarNumber] && !this.state.variableBodyExampleMappings[lastVarNumber]) {
      // return { errorMessage: `Preencha a variável {{${lastVarNumber}}} antes de adicionar outra.` };

      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: `É necessário preencher a variável {{${lastVarNumber}}} antes de adicionar outra.`
      });

      return;
    }


    const nextVarNumber = this.state.variablesBody.length + 1;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        bodyText: prevState.item.bodyText + ` {{${nextVarNumber}}}`,
      },
      variablesBody: [...prevState.variablesBody, nextVarNumber],
    }));
  };


  addVariableHeader = () => {
    const lastVarNumber = this.state.variablesHeader[this.state.variablesHeader.length - 1];

    // Verifica se a última variável foi preenchida antes de adicionar outra
    if (lastVarNumber && !this.state.variableHeaderSelectMappings[lastVarNumber] && !this.state.variableHeaderExampleMappings[lastVarNumber]) {
      // return { errorMessage: `Preencha a variável {{${lastVarNumber}}} antes de adicionar outra.` };

      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: `É necessário preencher a variável {{${lastVarNumber}}} antes de adicionar outra.`
      });

      return;
    }


    const nextVarNumber = this.state.variablesHeader.length + 1;

    PapiroConsole.log(nextVarNumber)
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        headerText: prevState.item.headerText + ` {{${nextVarNumber}}}`,
      },
      variablesHeader: [...prevState.variablesHeader, nextVarNumber],
    }));
  };

  handleTextChange = (event) => {
    let newText = event.target.value;
    const name = event.target.name;

    PapiroConsole.log("name")
    PapiroConsole.log(name)

    PapiroConsole.log("newText")
    PapiroConsole.log(newText)
    var matches = [...newText.matchAll(/\{\{(\d+)\}\}/g)].map((m) => parseInt(m[1]));
    matches = [...new Set(matches)].sort((a, b) => a - b);

    PapiroConsole.log("matches")
    PapiroConsole.log(matches)

    const correctedVariables = matches.map((_, index) => index + 1);
    PapiroConsole.log(correctedVariables)
    PapiroConsole.log("correctedVariables")
    const mapping = Object.fromEntries(matches.map((num, index) => [num, correctedVariables[index]]));
    PapiroConsole.log("mapping")
    PapiroConsole.log(mapping)

    let correctedText = newText.replace(/\{\{(\d+)\}\}/g, (_, num) => `{{${mapping[num]}}}`);
    PapiroConsole.log("correctedText")
    PapiroConsole.log(correctedText)






    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: correctedText,
      },

    }));

    if (name == "headerText") {
      let updatedMappings = Object.fromEntries(
        matches.map((num, index) => [correctedVariables[index], this.state.variableHeaderSelectMappings[num] || null])
      );
      const updatedTexts = Object.fromEntries(
        matches.map((num, index) => [correctedVariables[index], this.state.variableHeaderExampleMappings[num] || ""])
      );
      this.setState({ variablesHeader: correctedVariables, variableHeaderSelectMappings: updatedMappings, variableHeaderExampleMappings: updatedTexts })
    }
    else if (name == "bodyText") {
      let updatedMappings = Object.fromEntries(
        matches.map((num, index) => [correctedVariables[index], this.state.variableBodySelectMappings[num] || null])
      );
      const updatedTexts = Object.fromEntries(
        matches.map((num, index) => [correctedVariables[index], this.state.variableBodyExampleMappings[num] || ""])
      );
      this.setState({ variablesBody: correctedVariables, variableBodySelectMappings: updatedMappings, variableBodyExampleMappings: updatedTexts })
    }


  };

  handleBodyVariableChange = (varNumber, newValue) => {
    this.setState((prevState) => ({
      variableBodySelectMappings: { ...prevState.variableBodySelectMappings, [varNumber]: newValue },
    }));
  };

  handleHeaderVariableChange = (varNumber, newValue) => {
    this.setState((prevState) => ({
      variableHeaderSelectMappings: { ...prevState.variableHeaderSelectMappings, [varNumber]: newValue },
    }));
  };


  handleExampleInputChange = (varNumber, event) => {

    const name = event.target.name;

    if (name == "headerExample") {
      this.setState((prevState) => ({
        variableHeaderExampleMappings: { ...prevState.variableHeaderExampleMappings, [varNumber]: event.target.value },

      }));
    }
    else if (name == "bodyExample") {
      this.setState((prevState) => ({
        variableBodyExampleMappings: { ...prevState.variableBodyExampleMappings, [varNumber]: event.target.value },

      }));

    }

  };


  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    const { name, languageCode, bodyText, headerText, footerText, registrationnumberrequestid, templatetype, satisfactionsurveyid } = this.state.item;
    const { buttons, showForm, buttonText, buttonUrl, buttonUrlSuffixExample, error } = this.state;

    PapiroConsole.log("this.state.variablesBody")
    PapiroConsole.log(this.state.variablesBody)

    PapiroConsole.log("this.state.variablesHeader")
    PapiroConsole.log(this.state.variablesHeader)

    PapiroConsole.log("this.state.templateVariablesOptions")
    PapiroConsole.log(this.state.templateVariablesOptions)


    PapiroConsole.log("this.state.variableHeaderSelectMappings")
    PapiroConsole.log(this.state.variableHeaderSelectMappings)

    PapiroConsole.log("this.state.variableBodySelectMappings")
    PapiroConsole.log(this.state.variableBodySelectMappings)

    PapiroConsole.log("this.state.variableBodyExampleMappings")
    PapiroConsole.log(this.state.variableBodyExampleMappings)

    PapiroConsole.log("this.state.variableHeaderExampleMappings")
    PapiroConsole.log(this.state.variableHeaderExampleMappings)







    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar style={{ backgroundColor: '#303f9f', color: 'white' }} className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />} {<FormattedMessage id="templates" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2} marginTop={1}>
                  <Grid item xs={11}>
                    <TextField fullWidth label={intl.formatMessage({ id: "name" })} onKeyDown={this.handleKeyDown} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} helperText={`${item.name.length}/512`} required />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Nome do seu modelo" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>


                  <Grid item xs={11}>
                    <TextField fullWidth label={intl.formatMessage({ id: "label" })} autoComplete='fname' variant='outlined' name='label' onChange={this.handleInputChange} value={item.label} helperText={`${item.label.length}/255`} required />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Label do seu modelo" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginBottom={1}>
                    <Autocomplete
                      options={this.state.registrationNumberRequestList}
                      getOptionLabel={(option) => option.phonenumber}
                      onChange={(event, value) => this.handleChangeAutoComplete('registrationnumberrequestid', value)}
                      renderInput={(params) => <TextField {...params} required label="Selecione o telefone" variant="outlined" />}
                      value={registrationnumberrequestid}

                    />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Número de telefone que vai fazer envio dos modelos" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  <Grid item xs={11} marginBottom={1}>
                    <SimpleAutoComplete
                      name="messageProviderId"
                      label={intl.formatMessage({ id: "select.message_provider" })}
                      options={this.state.optionsMessageProviders}
                      stateName='messageProviderId'
                      changeSelect={(stateName, value) => {
                        this.handleChangeAutoComplete(stateName, value)
                      }}
                      selected={item.messageProviderId}
                      htmlOptions={true}
                      required
                    />
                  </Grid>


                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="Provedor da mensagem escolhido" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>


                  <Grid item xs={11} marginBottom={1}>
                    <Autocomplete
                      options={this.state.templateTypeList}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => this.handleChangeAutoComplete('templatetype', value)}
                      renderInput={(params) => <TextField required {...params} label="Selecione o tipo do template" variant="outlined" />}
                      value={templatetype}
                      required
                    />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title="tipo do template escolhido" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  {templatetype != null && templatetype.id == 3 &&

                    <Grid item xs={11} marginBottom={1}>
                      <Autocomplete
                        options={this.state.satisfactionSurveyList}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => this.handleChangeAutoComplete('satisfactionsurveyid', value)}
                        renderInput={(params) => <TextField required={templatetype != null && templatetype.id == 3 ? true : false} {...params} label="Selecione a pesquisa de satisfação" variant="outlined" />}
                        value={satisfactionsurveyid}
                        required={templatetype != null && templatetype.id == 3 != null ? true : false}
                      />
                    </Grid>
                  }

                  {templatetype != null && templatetype.id == 3 &&

                    <Grid item xs={1} marginTop={1}>
                      <StyledTooltip title="Pesquisa de satisfação ativa no sistema" placement="right" arrow>
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </StyledTooltip>
                    </Grid>
                  }


                  <Grid item xs={11}>
                    <TextField fullWidth label="Cabeçalho" autoComplete='fname' variant='outlined' name='headerText' onChange={this.handleTextChange} value={headerText} helperText={`${item.headerText.length}/60`} />
                  </Grid>
                  <Grid item xs={1} marginTop={1}>
                    <StyledTooltip title={this.state.exampleText

                    } placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  {/* <Grid item xs={11} marginTop={2} >
                    <Button onClick={this.addVariableHeader} variant="contained" sx={{ mt: 2 }}>
                      Adicionar Variável
                    </Button>
                    <div>
                      {this.state.variablesHeader.map((num) => (
                        <div key={num} style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
                          <span style={{ marginRight: "8px" }}>{`{{${num}}}`}</span>
                          <Autocomplete
                            options={this.state.templateVariablesOptions}
                            value={this.state.variableMappings[num] || null}
                            onChange={(_, newValue) => this.handleVariableChange(num, newValue)}
                            renderInput={(params) => <TextField {...params} label="Associar variável" />}
                          />
                        </div>
                      ))}
                    </div>
                  </Grid> */}
                  <Grid item xs={11}  >
                    <Divider sx={{ borderBottomWidth: 5 }} />
                  </Grid>
                  <Grid item xs={12}  >
                    <Button onClick={this.addVariableHeader} variant="contained" sx={{ mt: 2 }}>
                      Adicionar Variável Cabeçalho da Mensagem
                    </Button>


                  </Grid>

                  <Grid item xs={12}  >
                    {this.state.variablesHeader.map((num) => (
                      <Grid container marginTop={2} spacing={1} >
                        <Grid item xs={0.5} marginTop={4} >
                          <span style={{ marginRight: "8px" }}>{`{{${num}}}`}</span>
                        </Grid>
                        <Grid item xs={5.25} marginTop={2} >
                          <Autocomplete
                            options={this.state.templateVariablesOptions}
                            getOptionLabel={(option) => option.displayname}
                            value={this.state.variableHeaderSelectMappings[num] || null}
                            onChange={(_, newValue) => this.handleHeaderVariableChange(num, newValue)}
                            renderInput={(params) => <TextField {...params} label="Associar variável" />}
                          />
                        </Grid>

                        <Grid item xs={5.25} marginTop={2} >
                          <TextField fullWidth label="Exemplo de Conteudo de variável" autoComplete='fname' variant='outlined' name='headerExample' onChange={(event) => this.handleExampleInputChange(num, event)} value={this.state.variableHeaderExampleMappings[num] || ""} />
                        </Grid>


                      </Grid>
                    ))}
                  </Grid>

                  <Grid item xs={11}  >
                    <Divider sx={{ borderBottomWidth: 5 }} />


                  </Grid>

                  {/* <Divider flexItem/> */}



                  <Grid item xs={11} marginTop={2}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginLeft: '3px', marginBottom: '12px' }} gutterBottom>
                      <FormattedMessage id="Corpo da mensagem" />
                    </Typography>
                    {/* <ReactQuill
                      name="bodyText"
                      value={bodyText}
                      style={{ height: 150, marginBottom: 32 }}
                      modules={this.reactQuillModules}
                      formats={this.reactQuillFormats}
                      fullWidth
                      variant="outlined"
                      id="bodyText"
                      required
                      onChange={(value) => this.handleChangeEditor(value, "bodyText")}
                    /> */}

                    <TextareaAutosize
                      minRows={5}
                      style={{
                        width: '100%',
                        fontSize: '16px',
                        padding: '8px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                      }}
                      label="Corpo"
                      name="bodyText"
                      variant="outlined"
                      fullWidth
                      multiline
                      value={bodyText}
                      onChange={this.handleTextChange}
                      required
                    />

                  </Grid>
                  <Grid item xs={1} marginTop={5}>
                    <StyledTooltip title={this.state.exampleText

                    } placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>


                  <Grid item xs={11}  >
                    <Divider sx={{ borderBottomWidth: 5 }} />


                  </Grid>

                  <Grid item xs={12} marginTop={2} >
                    <Button onClick={this.addVariableBody} variant="contained" sx={{ mt: 2 }}>
                      Adicionar Variável Corpo da mensagem
                    </Button>


                  </Grid>
                  <Grid item xs={12} >
                    {this.state.variablesBody.map((num) => (
                      <Grid container marginTop={2} spacing={1}>
                        <Grid item xs={0.5} marginTop={4} >
                          <span style={{ marginRight: "8px" }}>{`{{${num}}}`}</span>
                        </Grid>
                        <Grid item xs={5.25} marginTop={2} >
                          <Autocomplete
                            options={this.state.templateVariablesOptions}
                            getOptionLabel={(option) => option.displayname}
                            value={this.state.variableBodySelectMappings[num] || null}
                            onChange={(_, newValue) => this.handleBodyVariableChange(num, newValue)}
                            renderInput={(params) => <TextField {...params} label="Associar variável" />}
                          />
                        </Grid>
                        <Grid item xs={5.25} marginTop={2} >
                          <TextField fullWidth label="Exemplo de Conteudo de variável" autoComplete='fname' variant='outlined' name='bodyExample' onChange={(event) => this.handleExampleInputChange(num, event)} value={this.state.variableBodyExampleMappings[num] || ""} />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <Grid item xs={11}  >
                    <Divider sx={{ borderBottomWidth: 5 }} />


                  </Grid>



                  <Grid item xs={11} marginTop={2} >
                    <TextField fullWidth label={intl.formatMessage({ id: "whitelabelconfig.footer.text" })} autoComplete='fname' variant='outlined' name='footerText' onChange={this.handleInputChange} value={footerText} helperText={`${item.headerText.length}/60`} />
                  </Grid>
                  <Grid item xs={1} marginTop={2}>
                    <StyledTooltip title="Rodapé do modelo (não é possível adicionar variáveis)" placement="right" arrow>
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </StyledTooltip>
                  </Grid>

                  {/* <Grid item xs={12}>



                  </Grid> */}

                  {/* Botão para adicionar novos elementos */}
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={this.handleAddButtonClick}
                    // disabled={buttons.length >= 2}
                    >
                      Novo botão
                    </Button>
                    <Tooltip title="Você pode adicionar até dois botões.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  {/* Formulário para adicionar um botão */}
                  {showForm && (
                    <Grid item xs={11}>

                      <Grid item xs={12}>
                        <TextField
                          label="Texto do Botão"
                          value={buttonText}
                          onChange={(e) => this.handleInputChangeTable("buttonText", e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SimpleAutoComplete
                          name="buttonType"
                          label="Tipo do botão"
                          options={this.state.typeList}
                          stateName='buttonType'
                          changeSelect={(stateName, value) => {
                            this.handleChangeAutoComplete(stateName, value)
                          }}
                          selected={this.state.buttonType}
                          htmlOptions={true}

                        />
                      </Grid>

                      {this.state.buttonType != null && this.state.buttonType.id == 1 &&

                        <Grid item xs={12}>
                          <TextField
                            label="Digite aqui uma URL"
                            value={buttonUrl}
                            onChange={(e) => this.handleInputChangeTable("buttonUrl", e.target.value)}
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                      }


                      {this.state.buttonType != null && this.state.buttonType.id == 3 &&

                        <Grid item xs={12}>
                          <Grid container >
                            <Grid item xs={10}>
                              <TextField
                                label="Digite aqui a URL do site"
                                value={buttonUrl}
                                onChange={(e) => this.handleInputChangeTable("buttonUrl", e.target.value)}
                                fullWidth
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={1} style={{

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                              <span style={{ marginRight: "8px" }}>{`{{1}}`}</span>
                            </Grid>
                            <Grid item xs={1} marginTop={3}>
                              <StyledTooltip title="Neste campo forneça somente a url base o conteudo dinamico será trocado em executação " placement="right" arrow>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </StyledTooltip>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                label="Exemplo de URL da amostra(url completa)"
                                value={buttonUrlSuffixExample}
                                onChange={(e) => this.handleInputChangeTable("buttonUrlSuffixExample", e.target.value)}
                                fullWidth
                                margin="normal"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Autocomplete
                                options={this.state.templateVariablesOptions}
                                getOptionLabel={(option) => option.displayname}
                                value={this.state.buttonTemplateVariable}
                                onChange={(_, newValue) => this.handleChangeAutoComplete("buttonTemplateVariable", newValue)}
                                renderInput={(params) => <TextField {...params} label="Associar variável" />}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                      }

                      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
                        {error && <Typography color="error">{error}</Typography>}
                        <Button variant="contained" color="primary" onClick={this.handleAdd} style={{ marginTop: 10 }}>
                          Adicionar
                        </Button>
                      </Grid>


                    </Grid>
                  )}
                  <Grid item xs={11}>
                    {buttons.length > 0 && (
                      <TableContainer component={Paper} style={{ marginTop: 20 }}>
                        <Table sx={{ minWidth: 650, borderCollapse: "collapse" }}>
                          <TableHead>
                            <TableRow sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                              <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>Texto do Botão</TableCell>
                              <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>Tipo do Botão</TableCell>
                              <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>URL</TableCell>
                              <TableCell>Ações</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {buttons.map((button, index) => (
                              <TableRow key={index} sx={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                                <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>{button.text}</TableCell>
                                <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                  {button.buttonTypeName}
                                </TableCell>
                                <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
                                  <a href={button.url} target="_blank" rel="noopener noreferrer">
                                    {button.url}
                                  </a>
                                </TableCell>

                                <TableCell>
                                  <IconButton color="error" onClick={() => this.handleRemove(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    {/* Tabela para exibir e gerenciar os botões */}

                  </Grid>















                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>

                      <Grid item xs={11} sm={3}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                      <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div >
    );
  }
}

TemplateMessageRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(TemplateMessageRegistration)));
