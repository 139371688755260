import { shapes } from '@joint/plus';
import React, { ChangeEvent, ReactElement, useCallback, useState } from 'react';

import ReactQuill from 'react-quill';
import HelperCustomField from '../../HelperCustomField/HelperCustomField';
import Input from '../Input/Input';
import { useBaseInspector } from './useBaseInspector';
import { Switch } from '@mui/material';

interface Props {
  cell: shapes.app.ResponseOrientation;
  chatbotFlowId: string;
}

const cellProps = {
  label: ['attrs', 'label', 'text'],
  description: ['attrs', 'description', 'text'],
  isTextRich: ['attrs', 'isTextRich', 'isTextRich'],
};

const ResponseOrientationInspector = (props: Props): ReactElement => {
    const { cell, chatbotFlowId } = props;

    const [label, setLabel] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isTextRich, setIsTextRich] = useState<boolean>(false);

    const assignFormFields = useCallback((): void => {
        setLabel(cell.prop(cellProps.label));
        setDescription(cell.prop(cellProps.description));
        setIsTextRich(cell.prop(cellProps.isTextRich));
    }, [cell]);

    const changeCellProp = useBaseInspector({ cell, assignFormFields });

    return (
        <>
            <h1>Componente selecionado</h1>

            <label>Label
                <Input type="text"
                       placeholder="Enter label"
                       value={label}
                       disabled={true}
                />
            </label>

            <div style={{ marginBottom: '8px', marginTop: '8px'}}>
                <Switch
                    value={isTextRich}
                    checked={isTextRich}
                    onChange={(e:any) => changeCellProp(cellProps.isTextRich, e?.target?.checked)}     
                />
                <label>É um texto rico?</label>
            </div>

            {
                isTextRich
                ? (
                    <label>Descrição
                        <div style={{ resize: "vertical", overflow: "auto", height: "250px", marginBottom: "24px", border: "1px solid #ccc" }}>
                            <ReactQuill
                                style={{ height: '100%' }}
                                name="description"
                                fullWidth
                                variant="outlined"
                                id="descriprion"
                                value={description}
                                onChange={(value: any) => changeCellProp(cellProps.description, value)}
                            />
                        </div>
                    </label>
                )
                : (
                    <label>Descrição
                        <Input 
                            type="text"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => changeCellProp(cellProps.description, e.target.value)}
                        />
                    </label>
                )
            }

            <HelperCustomField
                isRichText={isTextRich}
                description={description}
                param1={cellProps.description}
                onChangeDescription={changeCellProp}
                chatbotFlowId={chatbotFlowId}
            />
        </>
    );
};

export default ResponseOrientationInspector;
