
import Api2Talk from "../api2talk";

const PATH = '/webchat-origin';

const postWebchatOriginSave = async (input) => { 
  try {
    const response = await Api2Talk.post(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllWebchatOrigin = async (webchatid) => {
  try {
    const response = await Api2Talk.get(`${PATH}/${webchatid}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
    postWebchatOriginSave,
    getAllWebchatOrigin
}