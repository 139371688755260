import { CircularProgress, TextField, Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles, useTheme } from '@mui/styles';
import PropTypes from "prop-types";
import React from 'react';
import { VariableSizeList } from 'react-window';


const LISTBOX_PADDING = 8; // px

const toggleItemActive = ({ doSomething, value }) => {
  //console.log(doSomething);
};

function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
	  ...style,
	  top: style.top + LISTBOX_PADDING,
	};
	let textToRender = ""
	if (dataSet.hasOwnProperty('group')) {
	  if (dataSet.group.length > 90)
	    textToRender = dataSet.group.substring(0, 90) + '...'
	  return (
		<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
		  {textToRender}
		</ListSubheader>
	  );
	}
	textToRender = dataSet[1].parentname ? dataSet[1].parentname : dataSet[1].title ? dataSet[1].title : dataSet[1].name
	
	if (textToRender.length > 90)
	  textToRender = textToRender.substring(0, 90) + '...'
	return (
	  <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
		{textToRender}
	  </Typography>
	);
  }
  
  const OuterElementContext = React.createContext({});
  
  const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
	  if (ref.current != null) {
		ref.current.resetAfterIndex(0, true);
	  }
	}, [data]);
	return ref;
  }
  
  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach((item) => {
	  itemData.push(item);
	  itemData.push(...(item.children || []));
	});
  
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
	  noSsr: true,
	});
  
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;
  
	const getChildSize = (child) => {
	  if (child.hasOwnProperty('group')) {
		return 48;
	  }
  
	  return itemSize;
	};
  
	const getHeight = () => {
	  if (itemCount > 8) {
		return 8 * itemSize;
	  }
	  return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};
  
	const gridRef = useResetCache(itemCount);
  
	return (
	  <div ref={ref}>
		<OuterElementContext.Provider value={other}>
		  <VariableSizeList
			itemData={itemData}
			height={getHeight() + 2 * LISTBOX_PADDING}
			width="100%"
			ref={gridRef}
			outerElementType={OuterElementType}
			innerElementType="ul"
			itemSize={(index) => getChildSize(itemData[index])}
			overscanCount={5}
			itemCount={itemCount}
		  >
			{renderRow}
		  </VariableSizeList>
		</OuterElementContext.Provider>
	  </div>
	);
  });
  
  ListboxComponent.propTypes = {
	children: PropTypes.node,
  };

function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
	  boxSizing: 'border-box',
	  '& ul': {
		padding: 0,
		margin: 0,
	  },
	},
  });

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));


  
const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];



const useStyles = makeStyles(theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },  
}));

export default function SimpleAutoComplete(props) {

  const { id, label, onFocus, options, optionsText, stateName, changeSelect, selected, required, disabled, created = false, customRenderOption, htmlOptions = false, loading = false, loadListFunction, defaultValueProp } = props;
 
  //console.log(options);
  const defaultvalue = defaultValueProp ? defaultValueProp : {id:0, name:''};
  const classes = useStyles();

  //var initIx = convertHtmlOption(selected ? selected : defaultvalue)

  const [opt, setOption] = React.useState(selected ? selected : defaultvalue);
  
  React.useEffect(() => {
    //let cnvrt = convertHtmlOption(selected ? selected : defaultvalue)
    setOption(selected ? selected : defaultvalue);
  }, [selected]);
  
  const handleChange = (event,value, reason) => {
	if(value == null){
      value= {id:0, name:''};
    }
    setOption(value);
    changeSelect(stateName, value);
	
  };

  const handleOnFocus = async (event,value) => {
    await onFocus();
  };

  function Result(){

      return (
	  	<Autocomplete
			id={stateName}
			options={options}
			autoHighlight
			value={opt}
			//open={controlOpen}
			PopperComponent={StyledPopper}
        	ListboxComponent={ListboxComponent}
			getOptionLabel={option =>  option.parentname ?  option.parentname : option.title ? option.title : option.name}
			getOptionDisabled={!created ? option => option.cancreatecalled === false : option => option }
			onChange={handleChange}
			onFocus={handleOnFocus}
			loading={loading}
			loadingText="Carregando..."
			style={{ fullWidth: true }}
			disabled={loading || disabled}
			renderInput={params => (
						<TextField {...params} label={label} variant="outlined" required={required != null ? required == false ? false : true : false} disabled={disabled} fullWidth
									InputProps={ (loading) ? {
										...params.InputProps,
										startAdornment: (
											<>
												{<CircularProgress color="inherit" size={30} />}
											</>
										)
									} : { ...params.InputProps }} />
					  )}
		  	renderOption={(props, option, state) => [props, option, state.index]}
          /*
		  renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.parentname ?  option.parentname : option.name}
              </li>
            );
          }}
		*/
		/>
	);
    
  }

  return (
    <Result />
  );
}

SimpleAutoComplete.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.object,
    checkbox: PropTypes.bool,
    created: PropTypes.bool
  };