import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../../styles/material-styles";
import { Grid, Button, Dialog, TextField } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import { AbilityContext } from '../../../../config/ability-context';
import { injectIntl } from 'react-intl';
import Api2Talk from "../../../../services/api2talk";
import PropTypes from 'prop-types';
import { PapiroConsole } from "../../../../utils/papiroConsole";
import * as componentActions from '../../../../redux/actions/phoneregistrationrequests';
import { bindActionCreators } from 'redux';
import SyncIcon from '@mui/icons-material/Sync';
import { FormattedMessage } from 'react-intl';
import { FormControl, FormLabel, FormControlLabel, FormGroup, Switch } from '@mui/material'



class ProviderSettings extends Component {

  constructor(props) {
    super(props);

    const { phoneregistrationrequests } = this.props;
    PapiroConsole.log("props provider")
    PapiroConsole.log(this.props)
    this.state = {
      requestnumber: phoneregistrationrequests.phoneregistrationrequests,
      item: {
        id: phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.id,
        baseurl: phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig && phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.baseurl ? phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.baseurl : null,
        messageprovidername: phoneregistrationrequests.phoneregistrationrequests.messageprovider ? phoneregistrationrequests.phoneregistrationrequests.messageprovider.name : null,
        accountsid: phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig && phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.accountsid ? phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.accountsid : null,
        authtoken: phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig && phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.authtoken ? phoneregistrationrequests.phoneregistrationrequests.messageproviderconfig.authtoken : null,
      },
      forceSynchronizationOfApprovedTemplates: false

    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.syncTemplates = this.syncTemplates.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
  }


  handleInputChange(e) {
    const name = e.target.name
    const value = e.target.value

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  handleChangeSwitch = (e) => {
    let { name, checked } = e.target

    this.setState({ forceSynchronizationOfApprovedTemplates: checked })
  }


  async syncTemplates(e) {


    const { getNotification, setLoadingState } = this.props;
    setLoadingState(true)
    PapiroConsole.log(this.state)
    let data = {
      id: this.state.requestnumber.id,
      forceSynchronizationOfApprovedTemplates: this.state.forceSynchronizationOfApprovedTemplates
    }

    //Api nula
    if (Api2Talk == null) {
      getNotification(false, true, 'error', "Api de mensageria não configurada.")
      return;
    }


    Api2Talk.put('registrationphonenumber/sync/template', data)
      .then((result) => {
        if (result.data.success) {
          PapiroConsole.log("objeto atualizado aqui")
          PapiroConsole.log(result)
          getNotification(false, true, 'success', "Sincronização realizada com sucesso")


        } else {
          const intl = this.props.intl;
          getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        } else {
          const intl = this.props.intl;
          getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      });

  }



  async handleSubmit(e) {

    e.preventDefault();
    const { getNotification, setLoadingState } = this.props;
    setLoadingState(true)

    let data = new FormData();
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    //Api nula
    if (Api2Talk == null) {
      getNotification(false, true, 'error', "Api de mensageria não configurada.")
      return;
    }
    for (let key in this.state.item) {
      data.append(key, this.state.item[key])
    }

    Api2Talk.put('messageproviderconfig', data, config)
      .then((result) => {
        if (result.data.success) {
          PapiroConsole.log("objeto atualizado aqui")
          PapiroConsole.log(result)
          getNotification(false, true, 'success', "Configurações de provedor atualizadas com sucesso")
          if (result.data && result.data.data) {
            this.props.updateMessageProviderConfig(result.data.data)
          }

        } else {
          const intl = this.props.intl;
          getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      })
      .catch((err) => {
        if (err.response && err.response.data.errors) {
          const intl = this.props.intl;
          getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        } else {
          const intl = this.props.intl;
          getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      });

  }



  componentDidMount() {
  }




  render() {

    const { item } = this.state;
    const { classes } = this.props;
    const intl = this.props.intl;

    PapiroConsole.log("props atualizada")
    PapiroConsole.log(this.props)

    return (

      <div>
        <form name='myForm' className={classes.form} encType='multipart/form-data' onSubmit={this.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>



            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" endIcon={<SyncIcon />} onClick={this.syncTemplates} >
                Sincronizar modelos padrões
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FormControl component="fieldset" variant="standard">

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.forceSynchronizationOfApprovedTemplates}
                        onChange={this.handleChangeSwitch}
                        name="forceSynchronizationOfApprovedTemplates" />
                    }
                    label={intl.formatMessage({ id: "Forçar sincronização de templates já aprovados?" })} />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Nome do provedor"
                variant="outlined"
                value={item.messageprovidername}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="URL api"
                variant="outlined"
                name="baseurl"
                value={item.baseurl}
                onChange={this.handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Número do aplicativo meta associado ao numero registrado"
                variant="outlined"
                name="accountsid"
                value={item.accountsid ? item.accountsid : ''}
                onChange={this.handleInputChange}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Token de acesso ao provedor"
                variant="outlined"
                value={item.authtoken ? item.authtoken : ''}
                name="authtoken"
                onChange={this.handleInputChange}
                disabled
              />
            </Grid>
            <Grid container style={{ marginTop: '25px' }}>

              <Grid item xs={4} />
              <Grid item xs={4} />
              {/* Container para alinhar o botão à direita */}
              <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>


    );
  }
}
ProviderSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  phoneregistrationrequests: state.phoneregistrationrequests
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);


//export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(ProviderSettings))));
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ProviderSettings)));
ProviderSettings.contextType = AbilityContext;