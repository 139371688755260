import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import SimpleHelp from "src/components/help";
import SimpleSelect from '../../../components/select/simple';

const MailSending = props => {
    const { intl, item } = props
    return (
        <Grid container spacing={2} marginTop={2} alignItems="center">
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'server' })} autoComplete='fname' variant='outlined' name='server' onChange={props.handleInputChange} value={item.server} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.smtp.sending.server" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label="E-mail" autoComplete='fname' variant='outlined' name='fromMail' onChange={props.handleInputChange} value={item.fromMail} type="email" />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message="Email que será utilizado para enviar mensagens" />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'port' })} autoComplete='fname' variant='outlined' name='port' onChange={props.handleInputChange} value={item.port} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.smtp.port" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect label={intl.formatMessage({ id: 'crypto' })} options={props.CryptoList} stateName='cryptotype' name='cryptotype' changeSelect={props.changeValues} selected={item.cryptotype} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.smtp.encryption" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: 'user' })} autoComplete='fname' variant='outlined' name='user' onChange={props.handleInputChange} value={item.user} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.smtp.user.email" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField type="password" fullWidth label={intl.formatMessage({ id: 'password' })} autoComplete='fname' variant='outlined' name='password' onChange={props.handleInputChange} value={item.password} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.smtp.user.password" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect options={props.CloudTypeList} label={intl.formatMessage({ id: 'cloud.type' })} name='cloudType' stateName='cloudType' changeSelect={props.changeValues} selected={item.cloudType} disabled />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "cloud.type.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <SimpleSelect options={props.AuthTypeList} label={intl.formatMessage({ id: 'auth.type' })} name='authType' stateName='authType' changeSelect={props.changeValues} selected={item.authType} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.type.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Client Id"} autoComplete='fname' variant='outlined' name='clientId' onChange={props.handleInputChange} value={item.clientId} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "client.id.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Client Secret"} autoComplete='fname' variant='outlined' name='clientSecret' onChange={props.handleInputChange} value={item.clientSecret} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "client.secret.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Redirect Uris"} autoComplete='fname' variant='outlined' name='redirectUris' onChange={props.handleInputChange} value={item.redirectUris} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "redirect.uris.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Tenant Id"} autoComplete='fname' variant='outlined' name='tenantId' onChange={props.handleInputChange} value={item.tenantId} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "tenant.id.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={"Project Id"} autoComplete='fname' variant='outlined' name='projectId' onChange={props.handleInputChange} value={item.projectId} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "project.id.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Token Uri'} autoComplete='fname' variant='outlined' name='tokenUri' onChange={props.handleInputChange} value={item.tokenUri} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "token.uri.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Auth Uri'} autoComplete='fname' variant='outlined' name='authUri' onChange={props.handleInputChange} value={item.authUri} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.uri.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={'Auth Provider X509 Cert Url'} autoComplete='fname' variant='outlined' name='authProviderX509CertUrl' onChange={props.handleInputChange} value={item.authProviderX509CertUrl} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "auth.provider.x509.cert.url.smtp" })} />
            </Grid>
            <Grid item xs={11} sm={11}>
                <TextField fullWidth label={intl.formatMessage({ id: "config.edit.label.send.test.email" })} autoComplete='fname' variant='outlined' name='emailtest' onChange={props.handleInputChange} value={item.emailtest} />
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.test.email" })} />
            </Grid>
            <Grid item xs={4} sm={4}>
                <Button
                    onClick={props.testSendMail}
                    fullWidth
                    variant='contained'
                    color='primary'
                >
                    {intl.formatMessage({ id: "test" })}
                </Button>
            </Grid>
            <Grid item xs={1} sm={1}>
                <SimpleHelp message={intl.formatMessage({ id: "organization.account.email.registration.test.message.box.info" })} />
            </Grid>
        </Grid >
    )
}

export default MailSending