import { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import * as componentActions from '../../redux/actions/channels.js';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context';
import HeaderMenu from "../../components/header-menu";
import Api from "../../services/api";
import clsx from 'clsx';
import { Button, CircularProgress, Container, CssBaseline, Grid, Switch, TextField, Typography, Avatar} from "@mui/material";
import { browserHistory } from '../../helpers/history';
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { styled } from '@mui/system';
import { Tabs, Tab, Paper } from '@mui/material';
import { Overlay } from "../../styles/global.js";
import ChatIcon from '@mui/icons-material/Chat';


import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import WebchatGeneralData from "./components/webchat-general-data/index.js";
import WebchatMessageProviderSetting from "./components/webchat-message-provider-setting/index.js";
import WebchatSetting from "./components/webchat-setting/index.js";
import WebchatChannel from "./components/webchat-channel/index.js";
import { PapiroConsole } from "../../utils/papiroConsole.js";
import WebchatOrigin from "./components/webchat-origin/index.js";

const MyPaper = styled(Paper)({
  backgroundColor: (theme) => theme.palette.background.paper,
});

const MyTabs = styled(Tabs)({
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.primary.contrastText,
});

class WebchatEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, webchats } = this.props;

    this.globalAdm = false;
    this.isClient = true;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!webchats || (webchats && !webchats.webchat)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/webchats`
    }

    this.state = {
      loading: false,
      tabSelected: 0,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      openCancelRegisterNumber: false,
      tabSelected: 0,
      isDefault: false,
    };


    this.closeNotification = this.closeNotification.bind(this);
    this.getNotification = this.getNotification.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
    this.setIsDefault = this.setIsDefault.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }


  getNotification(loading, openNotification, notificationVariant, notificationMessage) {

    this.setState({ loading: loading, openNotification: openNotification, notificationVariant: notificationVariant, notificationMessage: notificationMessage });
  }

  setLoadingState(loading) {

    this.setState({ loading: loading });
  }

  setIsDefault(value) {
    PapiroConsole.log(value)
    PapiroConsole.log("setIsDefault")
    this.setState({ isDefault: value})
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, intl } = this.props;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <ChatIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "edit.webchat" })}
              </Typography>

              <div style={{ marginTop: '20px' }}>
                <MyPaper square>
                  <MyTabs
                    value={this.state.tabSelected}
                    onChange={this.handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    centered
                  >
                    <Tab label="Dados Gerais" />
                    <Tab label="Configurações" />
                    <Tab label="Configurações do provedor" />
                    <Tab label="Canais" />
                    <Tab label="Origens" />
                  </MyTabs>
                </MyPaper>
                
                {this.state.tabSelected === 0 && <WebchatGeneralData getNotification={this.getNotification} setLoadingState={this.setLoadingState} setIsDefault={this.setIsDefault} />}
                {this.state.tabSelected === 1 && <WebchatSetting getNotification={this.getNotification} setLoadingState={this.setLoadingState} />}
                {this.state.tabSelected === 2 && <WebchatMessageProviderSetting getNotification={this.getNotification} setLoadingState={this.setLoadingState} />}
                {this.state.tabSelected === 3 && <WebchatChannel getNotification={this.getNotification} setLoadingState={this.setLoadingState} isWebchatDefault={this.state.isDefault} />}
                {this.state.tabSelected === 4 && <WebchatOrigin getNotification={this.getNotification} setLoadingState={this.setLoadingState}/>}

              </div>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    )
  }
}

WebchatEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  webchats: state.webchats
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(WebchatEdit)));

WebchatEdit.contextType = AbilityContext;